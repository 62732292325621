import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser'; // Updated import for emailjs library
import { Snackbar, Alert } from '@mui/material'; // Import Alert component for better snackbar styling

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ isValid, theme, isTouched }) => (isTouched && !isValid ? 'red' : theme.text_secondary)};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const SnackbarStyled = styled(Snackbar)`
  /* Add any custom styling for Snackbar if needed */
`;

const Contact = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [subjectValid, setSubjectValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [subjectTouched, setSubjectTouched] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid) {
      emailjs.sendForm('service_x9lyp52', 'template_c6bbk0k', formRef.current, 'C88miSXmwp3ymVEq-')
        .then((result) => {
          console.log(result.text);
          setOpenSnackbar(true);
          e.target.reset(); // Reset form after successful submission
          setFormValid(false); // Reset form validity after reset
          setEmailTouched(false);
          setSubjectTouched(false);
          setNameTouched(false);
        }, (error) => {
          console.log(error.text);
        });
    } else {
      console.log("Form validation failed");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'from_email':
        setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
        setEmailTouched(true);
        break;
      case 'from_name':
        setNameValid(value.trim() !== '');
        setNameTouched(true);
        break;
      case 'subject':
        setSubjectValid(value.trim() !== '');
        setSubjectTouched(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFormValid(emailValid && subjectValid && nameValid);
  }, [emailValid, subjectValid, nameValid]);

  return (
    <Container id="contact">
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Feel free to reach out to me for any questions or opportunities!</Desc>
        <ContactForm ref={formRef} onSubmit={handleSubmit}>
          <ContactTitle>Ping Me 🚀</ContactTitle>
          <ContactInput
            type="email"
            placeholder="Your Email"
            name="from_email"
            onChange={handleInputChange}
            isValid={emailValid}
            isTouched={emailTouched}
            required
          />
          {emailTouched && !emailValid && <ErrorMessage>Please enter a valid email address.</ErrorMessage>}
          <ContactInput
            type="text"
            placeholder="Your Name"
            name="from_name"
            onChange={handleInputChange}
            isValid={nameValid}
            isTouched={nameTouched}
            required
          />
          {nameTouched && !nameValid && <ErrorMessage>Please enter your name.</ErrorMessage>}
          <ContactInput
            type="text"
            placeholder="Subject"
            name="subject"
            onChange={handleInputChange}
            isValid={subjectValid}
            isTouched={subjectTouched}
            required
          />
          {subjectTouched && !subjectValid && <ErrorMessage>Please enter a subject.</ErrorMessage>}
          <ContactInputMessage
            placeholder="Message"
            rows="4"
            name="message"
            required
          />
          <ContactButton
            type="submit"
            value="Send"
            disabled={!formValid}
          />
        </ContactForm>
        <SnackbarStyled
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            Email sent successfully!
          </Alert>
        </SnackbarStyled>
      </Wrapper>
    </Container>
  );
};

export default Contact;
